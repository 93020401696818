import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"

const styles = theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    paddingBottom: theme.spacing.unit * 5,
    paddingTop: theme.spacing.unit * 5,
  },
  image: {
    width: 300,
    margin: "0 auto",
  },
  typography: {
    color: theme.palette.secondary.main,
    fontFamily: "'Mango', sans-serif",
    textAlign: "center",
  },
  divider: {
    height: 5,
    backgroundColor: theme.palette.secondary.main,
  },
})

const SectionHeader = ({ sectionName, classes }) => {
  return (
    <div className={classes.root}>
      <Typography
        component="h2"
        variant="h3"
        gutterBottom
        color="primary"
        className={classes.typography}
      >
        {sectionName}
      </Typography>
      <Divider className={classes.divider} />
    </div>
  )
}

export default withStyles(styles)(SectionHeader)
