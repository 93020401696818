import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import withRoot from "../withRoot"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

export const BlackTextTypography = withStyles({
  root: {
    color: "black",
  },
})(Typography)

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 5,
    marginBottom: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 2,
    [theme.breakpoints.up("md")]: {
      width: 960,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    margin: "0 auto",
  },
  button: {
    margin: theme.spacing.unit,
  },
})

const Tickets = ({ classes }) => (
  <Layout>
    <SEO title="Tickets" />
    <Paper className={classes.root} elevation={1}>
      <BlackTextTypography variant="h5" component="h3">
        Wanneer begint de verkoop?
      </BlackTextTypography>
      <BlackTextTypography component="p">
        De ticketverkoop start op 7 apr 2024 12:00 via deze <a href={'https://shops.link2ticket.nl/12Hwa5'}>link</a>.
      </BlackTextTypography>
    </Paper>
    <Paper className={classes.root} elevation={1}>
      <BlackTextTypography component="p">
        Met de aanschaf van een ticket ga je akkoord met de{" "}
        <a href={`../../Algemene voorwaarden bezoekers KNSRBeach.pdf`} download>
          Algemene Voorwaarden
        </a>
      </BlackTextTypography>
    </Paper>
  </Layout>
)

export default withRoot(withStyles(styles)(Tickets))
