import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  buttonLoc: {
    position: "fixed",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: "3.2rem",
    margin: "1.6rem 0",
    zIndex: 1,
  },
  video: {
    height: "calc(100vh - 123px)",
    overflowY: "hidden",
    position: "relative",
    // [theme.breakpoints.down("sm")]: {
    //   height: "100%",
    // },
  },
  videoOverlay: {
    position: "absolute",
    top: "0",
    bottom: "0",
    width: "100%",
    // transform: "translateX(-50%) translateY(-50%)",
    // left: "50%",
  },
})

const IndexLayout = ({ content, video, overlay, popButton, classes }) => {
  return (
    <>
      <div className={classes.video}>
        {video}
        <div className={classes.videoOverlay}>{overlay}</div>
      </div>
      {content}
      <div className={classes.buttonLoc}>{popButton}</div>
    </>
  )
}

IndexLayout.propTypes = {
  content: PropTypes.node.isRequired,
  popButton: PropTypes.node.isRequired,
}

export default withStyles(styles)(IndexLayout)
