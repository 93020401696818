import React from "react"
import { withStyles } from "@material-ui/core/styles"
import withRoot from "../withRoot"

import SectionHeader from "./sectionHeader"
import { Typography } from "@material-ui/core"

const styles = theme => ({
  root: {
    margin: "0 auto",
    maxWidth: 480,
    textAlign: "center",
    // backgroundColor: theme.palette.primary.main,
  },
})

const Location = ({ data, classes }) => {
  return (
    <div id="locatie">
      <SectionHeader sectionName="LOCATIE" />
      <Typography className={classes.root} variant="body1" gutterBottom>
        Colorado Charlie
      </Typography>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9799.01954104167!2d4.2920196!3d52.1205885!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe67246496282fa0!2sColorado+Charlie!5e0!3m2!1snl!2ses!4v1562079791969!5m2!1snl!2ses"
        title="locatie"
        width="100%"
        height="400"
        frameBorder="0"
        style={{ border: 0 }}
      />
    </div>
  )
}
export default withRoot(withStyles(styles)(Location))
