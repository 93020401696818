import React from "react"
import { withStyles } from "@material-ui/core/styles"
import withRoot from "../withRoot"
import TicketsButton from "../components/ticketsButton"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
// import Lineup from "../components/lineup"
import About from "../components/about"
import Location from "../components/location"
import Faq from "../components/faq"

import IndexLayout from "../components/indexLayout"
import SEO from "../components/seo"
// import SaveDateLayout from "../components/saveDateLayout"
// import Image from "../components/image"

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  video: {
    width: "100%",
    objectFit: "cover",
    objectPosition: "0px 25%",
  },
  audioButton: {
    position: "absolute",
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 2,
  },
  overlay: {
    display: "flex",
    height: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  cell: {
    margin: 20,
    display: "flex",
    alignItems: "center",
    flexBasis: "50%",
    [theme.breakpoints.down("xs")]: {
      minHeight: "0",
    },
  },
})

const Index = ({ classes }) => {
  return (
    // <SaveDateLayout />
    <Layout>
      <IndexLayout
        popButton={<TicketsButton />}
        video={
          <>
            <Banner />
            {/* <video
              id="myVideo"
              autoPlay
              loop
              muted={mute}
              className={classes.video}
            >
              <source
                src="https://s3-eu-west-1.amazonaws.com/randommolmeets/KNSRBEACH_FINAL_FINAL.webm"
                type="video/webm"
              />
              <source src="https://dump.video/i/6lS6ry.mp4" type="video/mp4" />
            </video>
            <IconButton
              onClick={toggleMute}
              color="inherit"
              aria-label="Menu"
              className={classes.audioButton}
            >
              {mute ? (
                <VolumeOffIcon style={{ fontSize: 30, color: "#1E3B6F" }} />
              ) : (
                <VolumeUpIcon style={{ fontSize: 30, color: "#1E3B6F" }} />
              )}
            </IconButton> */}
          </>
        }
        overlay={
          <div className={classes.overlay}>
            <div className={classes.cell} style={{ flexGrow: 1 }}>
              <img
                style={{ maxHeight: "100%" }}
                src={`../knsr-layer.png`}
                alt="knsr"
              />
            </div>
            <div className={classes.cell} style={{ flexGrow: 3 }}>
              <img
                style={{ maxHeight: "100%" }}
                src={`../beach-layer.png`}
                alt="beach"
              />
            </div>
          </div>
        }
        content={
          <div>
            <SEO title="KNSRBeach" />
            <div className={classes.root}>
              <About />
              {/* <Lineup /> */}
              <Location />
              <Faq />
            </div>
          </div>
        }
      />
    </Layout>
  )
}

const Banner = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "banner-empty.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 3400) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        style={{ position: "static" }}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    )}
  />
)

export default withRoot(withStyles(styles)(Index))
