import React, { useState } from "react"
import { withStyles } from "@material-ui/core/styles"
import withRoot from "../withRoot"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import SectionHeader from "./sectionHeader"
import { BlackTextTypography } from "../pages/tickets"

const styles = theme => ({
  root: {
    maxWidth: 960,
    margin: "0 auto",
    paddingBottom: theme.spacing.unit * 5,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    color: "black",
  },
  text: {
    color: "black",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
})

const Faq = ({ data, classes }) => {
  const [expanded, setExpended] = useState(null)

  const handleChange = panel => (event, expanded) => {
    setExpended(expanded ? panel : false)
  }

  return (
    <div id="faq">
      <SectionHeader sectionName="VEELGESTELDE VRAGEN" />
      <div className={classes.root}>
        {/* <ExpansionPanel
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <BlackTextTypography className={classes.heading}>
              Vanaf en tot hoe laat duurt K.N.S.R.Beach?
            </BlackTextTypography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BlackTextTypography>
              K.N.S.R.Beach begint op vrijdag 8 juli om 15.00 en duurt tot 23.30
            </BlackTextTypography>
          </ExpansionPanelDetails>
        </ExpansionPanel> */}
        {/* <ExpansionPanel
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <BlackTextTypography className={classes.heading}>
              Hoe kan ik kaarten kopen?
            </BlackTextTypography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BlackTextTypography className={classes.text}>
              Kaarten kopen kan via deze. Druk op{" "}
              <a href="https://store.ticketing.cm.com/knsrbeach">koop tickets</a>!
            </BlackTextTypography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <BlackTextTypography className={classes.heading}>
              Vanaf wanneer begint de kaartverkoop?
            </BlackTextTypography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BlackTextTypography className={classes.text}>
              Opgelet: De kaartverkoop begint op zaterdag 1 april om 15:00.
            </BlackTextTypography>
          </ExpansionPanelDetails>
        </ExpansionPanel> */}
        <ExpansionPanel
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <BlackTextTypography className={classes.heading}>
              Hoe kom ik er met het OV?{" "}
            </BlackTextTypography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BlackTextTypography className={classes.text}>
              Afhankelijk van je startpunt is het het eenvoudigst om naar tram /
              bushalte ''Zwarte Pad'' (Den Haag) te gaan en vanaf daar te
              wandelen (180 meter).
            </BlackTextTypography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {/*<ExpansionPanel
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <BlackTextTypography className={classes.heading}>
              Hoe kom ik aan munten?
            </BlackTextTypography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BlackTextTypography className={classes.text}>
              Munten kun je op de locatie zelf aanschaffen.
            </BlackTextTypography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <BlackTextTypography className={classes.heading}>
              Wat voor weer wordt het?
            </BlackTextTypography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BlackTextTypography className={classes.text}>
              Hier kunnen we nu nog geen uitspraken over doen, maar het belooft
              sowieso een mooie dag te worden!
            </BlackTextTypography>
          </ExpansionPanelDetails>
        </ExpansionPanel> */}
      </div>
    </div>
  )
}
export default withRoot(withStyles(styles)(Faq))
